<template>
  <div id="calendar-app">
    <div class="vx-card app-fixed-height">
      <VuePerfectScrollbar class="scroll-area" :settings="settings">
        <full-calendar
          class="w-full select-none"
          :events="calendarEvents"
          locale="en"
          @dayClick="openAddNewEvent"
          @eventClick="openEditEvent"
        >
          <!-- HEADER LEFT -->
          <div slot="fc-header-left" class="flex flex-wrap sm:justify-start justify-center">
            <div
              v-for="(label, index) in calendarLabels"
              :key="index"
              class="flex items-center mr-4 mb-2"
            >
              <div class="h-3 w-3 inline-block rounded-full mr-2" :class="'bg-' + label.color"></div>
              <span>{{ label.text }}</span>
            </div>
          </div>
        </full-calendar>
      </VuePerfectScrollbar>
    </div>
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import shapeFormat from "../../mixins/shapeFormat.js";

export default {
  mixins: [shapeFormat],

  data() {
    return {
      orders: [],
      title: "",
      start: "",
      end: "",
      desc: "",
      id: 0,
      disabledFrom: false,
      labelLocal: "none",
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.3
      },

      activePromptAddEvent: false,
      activePromptEditEvent: false,
      calendarEvents: [
        {
          id: 1,
          title: "My Event",
          start: "2019-07-01",
          end: "2019-07-04",
          desc: "demo event",
          cssClass: "event-success",
          label: "personal"
        }
      ]
    };
  },
  watch: {
    async getCurrentBranch(){
      await this.reload();

    },
  },

  async mounted() {
    var res = null;
    var link = "";
    // if (this.$acl.check("admin")) {
    //   link = this.$store.state.apiURL + "/api/orderheader/admincalendar";
    // } else {
    //   link =
    //     this.$store.state.apiURL +
    //     "/api/orderheader/calendar/" +
    //     this.$store.state.auth.mybranchid;
    // }
      link =
        this.$store.state.apiURL +
        "/api/orderheader/calendar/" +
        this.getCurrentBranch.branchId;

    try {
      res = await this.$http.get(link, this.$store.state.auth.apiHeader);
      console.log('reponse from api ',res.data);
    } catch (error) {
      this.noticeError(error);
    }
    this.orders  = await res.data;
    this.setEvent(this.orders);
     this.$nextTick(function () {
    // Code that will run only after the
    // entire view has been rendered
     var list = document.getElementsByClassName("body-item");
    console.log(list.length);
    // element.classList.add("my-class");

  });





  },
  methods: {
    async reload(){
      var res = null;
      var link = "";
      link =
        this.$store.state.apiURL +
        "/api/orderheader/calendar/" +
        this.getCurrentBranch.branchId;

    try {
      res = await this.$http.get(link, this.$store.state.auth.apiHeader);


    } catch (error) {
      this.noticeError(error);
    }
    this.orders  = await res.data;
    this.setEvent(this.orders);
    },
    setEvent(ord) {
      this.calendarEvents = [];

      for (var i = 0; i < ord.length; i++) {
        // var css = "event-warning";
        var lab = "รอรับชุด";
        var startDate = null;
        var endDate = null;
        var title = "";


        var pDate = new Date(ord[i].pickupDate);
        var rDate = new Date(ord[i].returnDate);
        // var wDate = new Date(ord[i].afterWatchingDate);

        if(pDate > new Date("2000-01-01") && pDate !== null && pDate !== undefined)
        {
          lab = "รอรับชุด";
          startDate = ord[i].pickupDate;
          endDate = ord[i].pickupDate;
          title = "[P] " + ord[i].customerName + " " + ord[i].orderNumber;

          const eve = {
              id: i,
              title: title,
              start: startDate,
              end: endDate,
              desc: "",
              // classes: css,
              // cssClass: css,
              label: lab
          };

          eve.start = startDate;
          eve.end = endDate;
          eve.title = title;
          eve.cssClass = "event-warning";
          this.calendarEvents.push(eve);
        }

        if(rDate > new Date("2000-01-01") && rDate !== null && rDate !== undefined)
        {
          lab = "รอคืนชุด";
          startDate = ord[i].returnDate;
          endDate = ord[i].returnDate;
           title = "[R] " + ord[i].customerName + " " + ord[i].orderNumber;

          const eve = {
              // id: i.toString() + "_rDate",
              id: i,
              title: title,
              start: startDate,
              end: endDate,
              desc: "",
              // classes: css,
              // cssClass: css,
              label: lab
          };


          eve.start = startDate;
          eve.end = endDate;
          eve.title = title;
          eve.cssClass = "event-success";
          this.calendarEvents.push(eve);
        }


        // if(wDate > new Date("2000-01-01") && wDate !== null && wDate !== undefined)
        // {
        //   console.log("wDate OK");
        //   lab = "รอคืนจากการซัก";
        //   var thedate = new Date(ord[i].afterWatchingDate);
        //   startDate = this.addDay( thedate, 1 );
        //   endDate = this.addDay( thedate, 1 );
        //   title = "[W] " + ord[i].customerName + " " + ord[i].orderNumber;

        //   const eve = {
        //       id: i,
        //       title: title,
        //       start: startDate,
        //       end: endDate,
        //       desc: "",
        //       // classes: css,
        //       // cssClass: css,
        //       label: lab
        //   };
        //   eve.start = startDate;
        //   eve.end = endDate;
        //   eve.title = title;
        //   eve.cssClass = "event-danger";
        //   this.calendarEvents.push(eve);

        // }


        // this.calendarEvents.push(eve);
      }
    },
    addEvent() {
      const obj = {
        title: this.title,
        start: this.start,
        end: this.end,
        label: this.labelLocal,
        desc: this.desc
      };
      obj.cssClass = "event-" + this.labelColor(this.labelLocal);
      this.$store.dispatch("calendar/addEventToCalendar", obj);
    },
    clearFields() {
      this.title = this.end = this.desc = "";
      this.id = 0;
      this.labelLocal = "none";
    },
    addNewEventDialog(date) {
      this.clearFields();
      this.start = date;
      this.end = date;
      this.activePromptAddEvent = true;
    },
    openAddNewEvent(date) {
      this.disabledFrom = true;
      this.addNewEventDialog(date);
    },
    promptAddNewEvent(date) {
      this.disabledFrom = false;
      this.addNewEventDialog(date);
    },
    openEditEvent(event) {
      // console.log("@@@CALENDAR  event number >>> ", event.id);
      var ord = this.orders[event.id];
      window.open("/backoffice/order-detail/" + ord.orderHeaderId, "_blank");

      // const e = this.$store.getters["calendar/eventById"](event.id);
      // this.id = e.id;
      // this.title = e.title;
      // this.start = e.start;
      // this.end = e.end;
      // this.desc = e.desc;
      // this.activePromptEditEvent = true;
    },
    editEvent() {
      const obj = {
        id: this.id,
        title: this.title,
        start: this.start,
        end: this.end,
        label: this.labelLocal,
        desc: this.desc
      };
      obj.cssClass = "event-" + this.labelColor(this.labelLocal);
      this.$store.dispatch("calendar/ediCalendarEvent", obj);
    },
    removeEvent() {
      this.$store.dispatch("calendar/removeCalendarEvent", this.id);
    }
  },
  computed: {
     //  *** BEGIN : 2020-11-28 : Search By branches
    getCurrentBranch(){
      return this.$store.getters["auth/currentBranch"];
    },
    getMyBranches(){
      // console.log('@@@@PICKUP myBranches >>> ',this.$store.getters["auth/myBranches"]);
      return this.$store.getters["auth/myBranches"];
    },
    myPermission(){
      // console.log('@@@@PICKUP myPermission >>> ',this.$store.getters["auth/permission"]);

      return this.$store.getters["auth/permission"];
    },
     //  *** BEGIN : 2020-11-28 : Search By branches
    validForm() {
      return (
        this.title != "" &&
        this.start != "" &&
        this.end != "" &&
        Date.parse(this.end) - Date.parse(this.start) >= 0
      );
    },
    disabledDatesTo() {
      return { to: new Date(this.start) };
    },
    disabledDatesFrom() {
      return { from: new Date(this.end) };
    },
    calendarLabels() {
      var calendarLabels = [
        {
          text: "[P]:รอรับชุด",
          value: "รอรับชุด",
          color: "warning"
        },
        {
          text: "[R]:รอคืนชุด",
          value: "รอคืนชุด",
          color: "success"
        },
        // {
        //   text: "[W]:รอคืนจากการซัก",
        //   value: "รอคืนจากการซัก",
        //   color: "danger"
        // }
      ];
      return calendarLabels;
      // return this.$store.state.calendar.calendarLabels;
    },
    labelColor() {
      return label => {
        if (label == "รอรับชุด") return "warning";
        else if (label == "รับชุดแล้ว") return "success";
        else if (label == "รอคืนจากการซัก") return "danger";
      };
    }
  },

  components: {
    "full-calendar": require("vue-fullcalendar"),
    Datepicker,
    VuePerfectScrollbar
  }
};
</script>

<style lang="scss">
.event-warning{
  background-color: #FF9F43 !important;
}

.event-danger{
  background-color: #fd2075 !important;
  color: white !important;
}

.event-success{
  background-color: #28C76F !important;
}

.full-calendar-body .dates .dates-events .events-week .events-day {

    min-height: 100px !important;

}
// @import "@/assets/scss/vuesax/apps/calendar.scss";
</style>
